<template>
  <div class="content">
    <div class="page">
      <h2>九小场所管理</h2>
      <div class="message">
        系统汇集了各种基础要素，方便警员可快速查看本辖区的基础要素概况与详细情况，有效提升辖区重点区域防控。
      </div>
      <img src="../../../../assets/image/fbiImg/yaoshu.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "",
  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped lang="scss">
.content {
  padding: 0 16%;
  box-sizing: border-box;
  min-height: calc(100vh - 387px);
  width: 100%;
  margin-bottom: 50px;
  .page {
    margin-top: 50px;
    width: 100%;
    height: 720px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 30px;
    // position: relative;
    text-align: center;
    > h2 {
      text-align-last: left;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #333333;
      margin: 10px 0 30px 0;
    }
    .message {
      text-align-last: left;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #6b6b6b;
      margin-bottom: 50px;
    }
  }
}
</style>
